import React, { useState } from 'react';
import Header from '../components/Header';
import Contact from '../layouts/contact';
import Seo from '../components/Seo';

import TitleGroup from '../components/TitleGroup';
import { StaticImage } from 'gatsby-plugin-image';

import { useKeenSlider } from 'keen-slider/react';
import 'keen-slider/keen-slider.min.css';
import '../assets/css/index.scss';

const scenario = [
  {
    title: '行业大会',
    description: '参加者可穿上拥有自己设计或头像的衣服参加或作为纪念品',
    image: '../assets/images/mc-scenario-1.png',
  },
  {
    title: '偶像应援纪念品',
    description: '粉丝可拥有偶像指定设计但又有自己参与设计的产品',

    image: '../assets/images/mc-scenario-2.png',
  },
  {
    title: '员工福利',
    description: '提高员工参与感，具创意及独有的福利产品更提高员工对企业的归属感',
    image: '../assets/images/mc-scenario-3.png',
  },
];

const navigators = [
  {
    name: '企业定制',
    id: 'mcService',
    isActive: false,
  },
];

const sendMessage = async () => {
  const elements = document.getElementById('messageForm').elements;
  const fields = ['username', 'phone', 'email', 'comment'];
  const formData = new FormData();
  if (elements['username'].value === '' || elements['phone'].value === '') {
    return;
  }

  _hmt.push(['_trackEvent', 'click', '提交', '姓名：' + elements['username'].value + '，电话：' + elements['phone'].value + '，邮箱：' + elements['email'].value + '，留言：' + elements['comment'].value]);

  fields.forEach((f) => {
    console.log(elements, f);
    formData.append(f, elements[f].value);
  });
  const res = await fetch('https://mp.tootools.cn/api/comment', {
    method: 'POST',
    body: formData,
  });
  console.log(res);
  if (res.ok) {
    fields.forEach((f) => {
      elements[f].value = '';
    });
    alert('提交成功');
  }
};

const MassCustomization = () => {
  const [currentSlide1, setCurrentSlide1] = useState(0);
  const [currentSlide2, setCurrentSlide2] = useState(0);
  const [currentSlide3, setCurrentSlide3] = useState(0);
  const [loaded1, setLoaded1] = useState(false);
  const [loaded2, setLoaded2] = useState(false);
  const [loaded3, setLoaded3] = useState(false);
  const [slider1, instanceRef1] = useKeenSlider({
    initial: 3,
    slides: {
      origin: 'center',
      perView: 1,
    },
    loop: true,
    slideChanged(slider) {
      setCurrentSlide1(slider.track.details.rel);
    },
    created(slider) {
      setLoaded1(true);
      setCurrentSlide1(slider.track.details.rel);
    },
  });

  const [slider2, instanceRef2] = useKeenSlider({
    initial: 3,
    slides: {
      origin: 'center',
      perView: 1,
    },
    loop: true,
    slideChanged(slider) {
      setCurrentSlide2(slider.track.details.rel);
    },
    created(slider) {
      setLoaded2(true);
      setCurrentSlide2(slider.track.details.rel);
    },
  });

  const [slider3, instanceRef3] = useKeenSlider({
    initial: 3,
    slides: {
      origin: 'center',
      perView: 1,
    },
    loop: true,
    slideChanged(slider) {
      setCurrentSlide3(slider.track.details.rel);
    },
    created(slider) {
      setLoaded3(true);
      setCurrentSlide3(slider.track.details.rel);
    },
  });

  return (
    <main>
      <Seo />
      <Header navigators={navigators} />
      <div className='mc-service'>
        <section id='service' name='service' className='service'>
          <TitleGroup title='' subtitle='企业定制服务' />
          <div className='text-content'>
            ⼩岛凸凸企业批量定制，跳出传统厂房式工衣生产概念，讲求个性创意，保证企业统⼀定制的需求下也让员⼯拥有⾃⼰设计的空间，让企业员⼯服既有企业特⾊⼜能展示员⼯个性；不同的部门还可以规定不同的定制玩法。小岛凸凸实现了很多人当设计师的梦想，有想法就能定制。
          </div>
        </section>
        <section id='scenario' name='scenario' className='scenario'>
          <TitleGroup title='' subtitle='应用场景' />
          <div className='card-container desktop'>
            <div className='card'>
              <div className='image'>
                <StaticImage layout='CONSTRAINED' src='../assets/images/mc-scenario-1.png' alt='' />
              </div>
              <div className='title'>行业大会</div>
              <div className='description'>参加者可穿上拥有自己设计或头像的衣服参加或作为纪念品</div>
            </div>
            <div className='card'>
              <div className='image'>
                <StaticImage layout='CONSTRAINED' src='../assets/images/mc-scenario-2.png' alt='' />
              </div>
              <div className='title'>偶像应援纪念品</div>
              <div className='description'>粉丝可拥有偶像指定设计但又有自己参与设计的产品</div>
            </div>
            <div className='card'>
              <div className='image'>
                <StaticImage layout='CONSTRAINED' src='../assets/images/mc-scenario-3.png' alt='' />
              </div>
              <div className='title'>员工福利</div>
              <div className='description'>提高员工参与感，具创意及独有的福利产品更提高员工对企业的归属感</div>
            </div>
          </div>
          <div className='card-container mobile'>
            <div ref={slider1} className='keen-slider'>
              <div className={`keen-slider__slide number-slide1 ${currentSlide1 == 0 ? 'slide-active' : ''}`}>
                <div className='card'>
                  <div className='image'>
                    <StaticImage src='../assets/images/mc-scenario-1.png' alt='' />
                  </div>
                  <div className='title'>行业大会</div>
                  <div className='description'>参加者可穿上拥有自己设计或头像的衣服参加或作为纪念品</div>
                </div>
              </div>
              <div className={`keen-slider__slide number-slide2 ${currentSlide1 == 1 ? 'slide-active' : ''}`}>
                <div className='card'>
                  <div className='image'>
                    <StaticImage src='../assets/images/mc-scenario-2.png' alt='' />
                  </div>
                  <div className='title'>偶像应援纪念品</div>
                  <div className='description'>粉丝可拥有偶像指定设计但又有自己参与设计的产品</div>
                </div>
              </div>
              <div className={`keen-slider__slide number-slide3 ${currentSlide1 == 2 ? 'slide-active' : ''}`}>
                <div className='card'>
                  <div className='image'>
                    <StaticImage src='../assets/images/mc-scenario-3.png' alt='' />
                  </div>
                  <div className='title'>员工福利</div>
                  <div className='description'>提高员工参与感，具创意及独有的福利产品更提高员工对企业的归属感</div>
                </div>
              </div>
              <div className={`keen-slider__slide number-slide4 ${currentSlide1 == 3 ? 'slide-active' : ''}`}>
                <div className='card'>
                  <div className='image'>
                    <StaticImage src='../assets/images/mc-scenario-1.png' alt='' />
                  </div>
                  <div className='title'>行业大会</div>
                  <div className='description'>参加者可穿上拥有自己设计或头像的衣服参加或作为纪念品</div>
                </div>
              </div>
              <div className={`keen-slider__slide number-slide5 ${currentSlide1 == 4 ? 'slide-active' : ''}`}>
                <div className='card'>
                  <div className='image'>
                    <StaticImage src='../assets/images/mc-scenario-2.png' alt='' />
                  </div>
                  <div className='title'>偶像应援纪念品</div>
                  <div className='description'>粉丝可拥有偶像指定设计但又有自己参与设计的产品</div>
                </div>
              </div>
              <div className={`keen-slider__slide number-slide6 ${currentSlide1 == 5 ? 'slide-active' : ''}`}>
                <div className='card'>
                  <div className='image'>
                    <StaticImage src='../assets/images/mc-scenario-3.png' alt='' />
                  </div>
                  <div className='title'>员工福利</div>
                  <div className='description'>提高员工参与感，具创意及独有的福利产品更提高员工对企业的归属感</div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id='intro' name='intro' className='intro'>
          <TitleGroup title='' subtitle='企业定制介绍视频' />
          <div className='intro-content'>
            <div>
              <video controls src='http://mp-oss.tootools.cn/tootools.cn/mc.mp4' />
            </div>
            <div className='intro-right desktop'>
              <div className='intro-card'>
                <div className='image'>
                  <StaticImage src='../assets/images/mc-intro-1.png' alt='' />
                </div>
                <div className='description'>
                  多样贴纸，形状以及字体，
                  <br />
                  支持二次创作
                </div>
              </div>
              <div className='intro-card'>
                <div className='image'>
                  <StaticImage src='../assets/images/mc-intro-2.png' alt='' />
                </div>
                <div className='description'>艺术家联名合作</div>
              </div>
              <div className='intro-card'>
                <div className='image'>
                  <StaticImage src='../assets/images/mc-intro-3.png' alt='' />
                </div>
                <div className='description'>
                  凸凸想像TOOVERSE
                  <br />
                  你喜欢的样子我都有
                </div>
              </div>
            </div>

            <div className='intro-right mobile'>
              <div ref={slider2} className='keen-slider'>
                <div className={`keen-slider__slide number-slide1 ${currentSlide2 == 0 ? 'slide-active' : ''}`}>
                  <div className='intro-card'>
                    <div className='image'>
                      <StaticImage  src='../assets/images/mc-intro-1.png' alt='' />
                    </div>
                    <div className='description'>
                      多样贴纸，形状以及字体，
                      <br />
                      支持二次创作
                    </div>
                  </div>
                </div>
                <div className={`keen-slider__slide number-slide2 ${currentSlide2 == 1 ? 'slide-active' : ''}`}>
                  <div className='intro-card'>
                    <div className='image'>
                      <StaticImage src='../assets/images/mc-intro-2.png' alt='' />
                    </div>
                    <div className='description'>艺术家联名合作</div>
                  </div>
                </div>
                <div className={`keen-slider__slide number-slide3 ${currentSlide2 == 2 ? 'slide-active' : ''}`}>
                  <div className='intro-card'>
                    <div className='image'>
                      <StaticImage src='../assets/images/mc-intro-3.png' alt='' />
                    </div>
                    <div className='description'>
                      凸凸想像TOOVERSE
                      <br />
                      你喜欢的样子我都有
                    </div>
                  </div>
                </div>
                <div className={`keen-slider__slide number-slide4 ${currentSlide2 == 3 ? 'slide-active' : ''}`}>
                  <div className='intro-card'>
                    <div className='image'>
                      <StaticImage src='../assets/images/mc-intro-1.png' alt='' />
                    </div>
                    <div className='description'>
                      多样贴纸，形状以及字体，
                      <br />
                      支持二次创作
                    </div>
                  </div>
                </div>
                <div className={`keen-slider__slide number-slide5 ${currentSlide2 == 4 ? 'slide-active' : ''}`}>
                  <div className='intro-card'>
                    <div className='image'>
                      <StaticImage src='../assets/images/mc-intro-2.png' alt='' />
                    </div>
                    <div className='description'>艺术家联名合作</div>
                  </div>
                </div>
                <div className={`keen-slider__slide number-slide6 ${currentSlide2 == 5 ? 'slide-active' : ''}`}>
                  <div className='intro-card'>
                    <div className='image'>
                      <StaticImage src='../assets/images/mc-intro-3.png' alt='' />
                    </div>
                    <div className='description'>
                      凸凸想像TOOVERSE
                      <br />
                      你喜欢的样子我都有
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id='feature' name='feature' className='feature'>
          <TitleGroup title='' subtitle='产品特点' />
          <div class='content desktop'>
            <div className='feature-row'>
              <div className='feature-image'>
                <StaticImage src='../assets/images/feature-1.png' alt='' />
              </div>
              <div className='feature-text text-container'>
                <div class='text-bg'>01</div>
                <div className='text-content'>员工可以按照企业设计模版加入自己的小心思来定制员工服，有趣亲切。</div>
              </div>
            </div>
            <div className='feature-row feature-row-reverse'>
              <div className='feature-image'>
                <StaticImage src='../assets/images/feature-2.png' alt='' />
              </div>
              <div className='feature-text text-container'>
                <div class='text-bg'>02</div>
                <div className='text-content'>流程简单，员工完成设计后，服装可直接送到给员工，省去内部分发程序，简单便捷。</div>
              </div>
            </div>
            <div className='feature-row'>
              <div className='feature-image'>
                <StaticImage src='../assets/images/feature-3.png' alt='' />
              </div>
              <div className='feature-text text-container'>
                <div class='text-bg'>03</div>
                <div className='text-content'>企业不同部门或团队，可以拥有自己不同的设计工服，塑造团队精神。</div>
              </div>
            </div>
          </div>

          <div class='content mobile'>
            <div className='feature-row'>
              <div className='feature-image'>
                <StaticImage src='../assets/images/feature-m-1.jpg' alt='' />
              </div>
              <div className='feature-text text-container'>
                <div class='text-bg'>01</div>
                <div className='text-content'>员工可以按照企业设计模版加入自己的小心思来定制员工服，有趣亲切。</div>
              </div>
            </div>
            <div className='feature-row feature-row-reverse'>
              <div className='feature-image'>
                <StaticImage src='../assets/images/feature-m-2.jpg' alt='' />
              </div>
              <div className='feature-text text-container'>
                <div class='text-bg'>02</div>
                <div className='text-content'>流程简单，员工完成设计后，服装可直接送到给员工，省去内部分发程序，简单便捷。</div>
              </div>
            </div>
            <div className='feature-row'>
              <div className='feature-image'>
                <StaticImage src='../assets/images/feature-m-3.jpg' alt='' />
              </div>
              <div className='feature-text text-container'>
                <div class='text-bg'>03</div>
                <div className='text-content'>企业不同部门或团队，可以拥有自己不同的设计工服，塑造团队精神。</div>
              </div>
            </div>
          </div>
        </section>

        <section id='cases' name='cases' className='cases'>
          <TitleGroup title='' subtitle='合作案例' />
          <div className='card-container desktop'>
            <div className='card'>
              <div className='image'>
                <StaticImage src='../assets/images/cases-1.png' alt='' />
              </div>
              <div className='logo'>
                <StaticImage src='../assets/images/cases-logo-1.png' alt='' />
              </div>
            </div>
            <div className='card'>
              <div className='image'>
                <StaticImage src='../assets/images/cases-2.png' alt='' />
              </div>
              <div className='logo'>
                <StaticImage src='../assets/images/cases-logo-2.png' alt='' />
              </div>
            </div>
            <div className='card'>
              <div className='image'>
                <StaticImage src='../assets/images/cases-3.png' alt='' />
              </div>
              <div className='logo'>
                <StaticImage src='../assets/images/cases-logo-3.png' alt='' />
              </div>
            </div>
          </div>
          <div className='card-container mobile'>
            <div ref={slider3} className='keen-slider'>
              <div className={`keen-slider__slide number-slide1 ${currentSlide3 == 0 ? 'slide-active' : ''}`}>
                <div className='card'>
                  <div className='image'>
                    <StaticImage src='../assets/images/cases-1.png' alt='' />
                  </div>
                  <div className='logo'>
                    <StaticImage src='../assets/images/cases-logo-1.png' alt='' />
                  </div>
                </div>
              </div>
              <div className={`keen-slider__slide number-slide2 ${currentSlide3 == 1 ? 'slide-active' : ''}`}>
                <div className='card'>
                  <div className='image'>
                    <StaticImage src='../assets/images/cases-2.png' alt='' />
                  </div>
                  <div className='logo'>
                    <StaticImage src='../assets/images/cases-logo-2.png' alt='' />
                  </div>
                </div>
              </div>
              <div className={`keen-slider__slide number-slide3 ${currentSlide3 == 2 ? 'slide-active' : ''}`}>
                <div className='card'>
                  <div className='image'>
                    <StaticImage src='../assets/images/cases-3.png' alt='' />
                  </div>
                  <div className='logo'>
                    <StaticImage src='../assets/images/cases-logo-3.png' alt='' />
                  </div>
                </div>
              </div>
              <div className={`keen-slider__slide number-slide4 ${currentSlide3 == 3 ? 'slide-active' : ''}`}>
                <div className='card'>
                  <div className='image'>
                    <StaticImage src='../assets/images/cases-1.png' alt='' />
                  </div>
                  <div className='logo'>
                    <StaticImage src='../assets/images/cases-logo-1.png' alt='' />
                  </div>
                </div>
              </div>
              <div className={`keen-slider__slide number-slide5 ${currentSlide3 == 4 ? 'slide-active' : ''}`}>
                <div className='card'>
                  <div className='image'>
                    <StaticImage src='../assets/images/cases-2.png' alt='' />
                  </div>
                  <div className='logo'>
                    <StaticImage src='../assets/images/cases-logo-2.png' alt='' />
                  </div>
                </div>
              </div>
              <div className={`keen-slider__slide number-slide6 ${currentSlide3 == 5 ? 'slide-active' : ''}`}>
                <div className='card'>
                  <div className='image'>
                    <StaticImage src='../assets/images/cases-3.png' alt='' />
                  </div>
                  <div className='logo'>
                    <StaticImage src='../assets/images/cases-logo-3.png' alt='' />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id='message' name='message' className='message'>
          <TitleGroup title='' subtitle='在线留言' />

          <form className='message-form' id='messageForm' method='post' onSubmit={(e) => e.preventDefault()}>
            <div className='form-row'>
              <div className='form-item'>
                <label>
                  姓名<span style={{ color: 'red' }}>*</span>
                </label>
                <input maxLength={20} required type='text' name='username' id='username' placeholder='请输入您的姓名' />
              </div>
              <div className='form-item'>
                <label>
                  电话<span style={{ color: 'red' }}>*</span>
                </label>
                <input maxLength={11} required type='text' name='phone' id='phone' placeholder='请输入您的电话' />
              </div>
              <div className='form-item'>
                <label>邮箱</label>
                <input type='text' name='email' id='email' placeholder='请输入您的邮箱' />
              </div>
            </div>
            <div className='form-row'>
              <div className='form-item'>
                <label>留言</label>
                <textarea name='comment' id='comment' rows='1' placeholder='输入您的宝贵留言' />
              </div>
            </div>
            <div className='form-row'>
              <div className='form-item form-submit'>
                <button type='submit' onClick={sendMessage}>
                  提交
                </button>
              </div>
            </div>
          </form>
        </section>
      </div>
      <Contact email='cooperation@tootools.cn' phone='18100095751' contactName={'葛先生'} />
    </main>
  );
};

export default MassCustomization;
